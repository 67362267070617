<template lang="pug">
.form-date
  .form-field-label.filter
    q-input.date-input-class(
      v-model="currentFilter",
      :label="label",
      :id="name",
      :mask="currentMask",
      @click.stop="openDateModal()",
      readonly
    )
      template(v-slot:prepend)
        inline-svg.datetime-icon(:src="require(`@/assets/icons/calendar.svg`)", @click.stop="openDateModal()")
    q-dialog#q-datetime-modal(v-model="openModal")
      q-card.row.radius(flat)
        q-date(
          v-model="currentDate",
          :mask="mask",
          :locale="currentDatesLocale",
          :default-view="defaultView",
          today-btn
        )
          .row.justify-end
            q-btn.dialog-controls(
              flat,
              @click="openModal = false",
              :label="buttonCancelLabel",
              v-if="type !== 'datetime'"
            )
            q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type !== 'datetime'")
        q-time(v-model="currentDate", :mask="mask", format24h, v-if="type === 'datetime'", now-btn)
          .row.justify-end
            q-btn.dialog-controls(
              flat,
              @click="openModal = false",
              :label="buttonCancelLabel",
              v-if="type !== 'datetime'"
            )
            q-btn.dialog-controls(flat, @click="checkDate", :label="buttonCloseLabel", v-if="type === 'datetime'")
  q-icon.cancel-date-filter(name="cancel", v-if="isResettable", @click.stop="resetFilter()")
</template>

<script>
import { Datetime } from "vue-datetime";
import { format } from "date-fns";
import i18n from "@/plugins/vue-i18n";
import { formatDateAsISO } from "@/services/formatDateAsISO";

export default {
  components: {
    Datetime,
  },

  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      type: this.parentData.data[0].type,
      label: this.parentData.data[0].label,
      name: this.parentData.data[0].name,
      value: this.parentData.data[0].value,
      reset: this.parentData.data[0].reset === undefined ? true : this.parentData.data[0].reset,
      grid: this.parentData.grid,
      path: this.parentData.path,
      mask: this.parentData.mask || "DD.MM.YYYY",
      phrases: {},
      openModal: false,
      defaultView: this.parentData.data[0].defaultView || "Calendar",
      currentDate:
        this.parentData.data[0].type === "datetime"
          ? this.getCurrentDate("DD.MM.YYYY, HH:mm")
          : this.getCurrentDate("DD.MM.YYYY"),
    };
  },

  computed: {
    currentDatesLocale() {
      return i18n["messages"][this.current_locale]["date"];
    },

    buttonCloseLabel() {
      return i18n["messages"][this.current_locale]["buttonCloseLabel"];
    },

    buttonCancelLabel() {
      return i18n["messages"][this.current_locale]["buttonCancelLabel"];
    },

    today() {
      const dateTime = new Date();
      return format(dateTime, "yyyy/MM/dd");
    },

    currentFilter: {
      get() {
        let form_field = this.currentFilters[this.name];

        if (!form_field) {
          return undefined;
        }

        // we need to ensure date is formatted as ISO string
        const dateArr = form_field.split("T");

        if (dateArr.length > 1) {
          const dateInstance = new Date(form_field);
          if (this.type === "datetime") {
            return format(dateInstance, "dd.MM.yyyy, HH:mm");
          } else {
            return format(dateInstance, "dd.MM.yyyy");
          }
        } else {
          return form_field;
        }
      },
      set(value) {
        if (value) {
          // we need to ensure date is formatted as ISO string
          value = formatDateAsISO(value);
        }

        this.$store.commit("updateFilter", { grid_name: this.grid, filter: this.name, value: value });
      },
    },

    currentMask() {
      return this.mask === "DD.MM.YYYY, HH:mm" ? "##.##.####, ##:##" : "##.##.####";
    },

    isResettable() {
      if (!this.currentFilter) {
        // if default/value is not set
        return false;
      }

      return this.reset;
    },
  },

  beforeMount() {
    this.setDateFieldLocale();
    this.setDateFieldPhrases();
    this.setDateFieldValue();
  },

  methods: {
    resetFilter() {
      this.$store.commit("resetFilter", { grid_name: this.grid, filter: this.name });
    },

    stackLabelCheck() {
      return this.currentFilter !== null && this.currentFilter !== undefined && this.currentFilter !== "";
    },

    getCurrentDate(mask) {
      let resp = "";
      if (this.currentFilter) {
        resp = this.currentFilter;
      } else {
        const dateTime = new Date();
        if (mask === "DD.MM.YYYY, HH:mm") {
          resp = format(dateTime, "dd.MM.yyyy, HH:mm");
        } else {
          resp = format(dateTime, "dd.MM.yyyy");
        }
      }
      return resp;
    },

    checkDate() {
      this.currentFilter = this.currentDate;
      this.openModal = !this.openModal;
    },

    setDateFieldValue() {
      if (!this.value || this.currentFilter) {
        return;
      }

      this.currentFilter = this.value;
    },

    openDateModal() {
      this.openModal = true;
      this.currentDate = this.getCurrentDate(this.mask);
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/styles/filters/date";
.form-date {
  .date-input-class {
    input {
      font-size: 14px;
    }
  }
  .form-field-label.filter {
    .q-field__control {
      border: none !important;
    }
  }
}
</style>
