<template lang="pug">
.passport_fields-paragraph-edit-form
  .card
    .passport_fields-paragraph-edit-form-title {{ title }}
    form(@submit="submitForm")
      input(outlined, v-model="paragraph", placeholder="Параграф")
      input(outlined, v-model="value", placeholder="Значение")
      button(type="submit")
        div +
        p Изменить пункт

    q-icon.passport_fields-paragraph-form-close(name="close", @click="closeForm")
</template>

<script>
export default {
  props: {
    parentData: {
      type: Object,
      default: () => {},
    },
  },
  data: function () {
    return {
      paragraph: this.parentData.paragraph.paragraph_title || "",
      value: this.parentData.paragraph.value || "",
      title: this.parentData.data.heading_title,
      building_passport_heading_id: this.parentData.data.pg_passport_heading_id,
      id: this.parentData.paragraph.pg_passport_paragraph_id,
      building_id: this.parentData.building_id,
    };
  },

  watch: {},

  methods: {
    closeForm() {
      this.$emit("close");
    },

    submitForm(e) {
      e.preventDefault();
      const params = {
        title: this.paragraph,
        value: this.value,
        building_passport_heading_id: this.building_passport_heading_id,
        building_id: this.building_id,
      };

      this.$backend.update("/api/v3/building_passport_paragraphs", this.id, params).then(res => {
        this.$emit("change", {
          title_id: this.building_passport_heading_id,
          paragraph_id: this.id,
          value: this.value,
          name: this.paragraph,
        });
        this.$emit("close");
      });
    },
  },
};
</script>

<style lang="scss">
.card-label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  color: var(--dashboard-label-color);
}

.passport_fields-paragraph-edit-form {
  position: absolute;
  left: 0;
  top: 110%;
  z-index: 2;
  width: 100%;
  height: fit-content;

  .card {
    padding: 18px 16px;
    border: 1px solid #f8f8f8;
    filter: drop-shadow(0px 8px 24px rgba(44, 63, 88, 0.1));
  }

  &-title {
    font-size: 12px;
    font-weight: 600;
    color: var(--dashboard-label-color);
    margin-right: 30px;
  }

  form {
    input {
      background: var(--dashboard-card-background);
      color: var(--dashboard-label-color);
      border: 1px solid #d2d2d2;
      box-sizing: border-box;
      border-radius: 8px;
      font-size: 14px;
      font-weight: 500;
      width: 100%;
      height: 33px;
      line-height: 33px;
      padding: 0 16px;
      margin-top: 12px;
      outline: none;
    }
  }

  &-close {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: 16px;
    font-size: 20px;
    cursor: pointer;
  }

  button {
    color: #5b7aff;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 10px 0 0;

    div {
      font-size: 22px;
      display: inline-block;
      margin-right: 7px;
      font-weight: normal;
    }

    p {
      display: inline-block;
      font-size: 14px;
      margin: 0;
    }
  }
}
</style>
